export const NACH_CONST: { [key: string]: string } = {
    ENACH: 'E-NACH',
    PHYSICAL_NACH: 'Paper NACH',
};

export const LOAN_TYPE = {
    RECURRING_REVENUE_FINANCING: 'Recurring Revenue Financing',
    DEBT_SYNDICATION: 'Debt Syndication',
};

export const INVESTOR_TYPE = {
    NBFC: 'NBFC',
    NBFC_FOR_DEBT: 'NBFC FOR DEBT',
    INSTITUTIONAL: 'Institutional',
    FAMILY_OFFICE: 'Family Office',
    HNI: 'HNI',
    OTHERS: 'Others',
};
export const APPROVE_DEAL_TXT = {
    RRF: 'Approve',
    DS: 'Submit IPA',
};
export const MFA_SL_STATUS = {
    OPEN: 'open',
};

export const SIGNUP_INVESTOR_TYPE = {
    NBFC: 'NBFC',
    INSTITUTIONAL: 'Institutional',
    FAMILY_OFFICE: 'Family Office',
    HNI: 'HNI',
    OTHERS: 'Others',
};

export const JOB_ROLES = [
    { label: 'CEO', value: 'CEO' },
    { label: 'CFO', value: 'CFO' },
    { label: 'CXO', value: 'CXO' },
    { label: 'Finance Director', value: 'Finance Director' },
    { label: 'Finance Manager', value: 'Finance Manager' },
    { label: 'Accounts Manager', value: 'Accounts Manager' },
    { label: 'Others', value: 'Others' },
];
export const GST_FACTOR = 1.18;

export const EMAIL_NOT_VERIFIED_MESSAGE =
    'The account is pending verification. Please check your mailbox for verification email.';

export const OTHERS = 'Others';
export const additionalOption = [
    { label: 'Debt schedule and sanction Letters', value: 'Debt schedule and sanction Letters' },
    { label: 'Bank Statements', value: 'Bank Statements' },
    { label: 'Audited Financials', value: 'Audited Financials' },
    { label: 'MIS', value: 'MIS' },
    { label: 'Equity Infusion/Equity raise docs', value: 'Equity Infusion/Equity raise docs' },
    { label: 'Receivables Ageing', value: 'Receivables Ageing' },
    { label: 'GST Data and fillings', value: 'GST Data and fillings' },
    { label: 'Bureau Reports', value: 'Bureau Reports' },
    { label: 'Invoicing Data', value: 'Invoicing Data' },
    { label: 'Pitch Deck and Other Documents', value: 'Pitch Deck and Other Documents' },
    { label: 'Financial Projections', value: 'Financial Projections' },
    { label: 'ITR fillings', value: 'ITR fillings' },
    { label: 'Ageing Details', value: 'Ageing Details' },
    { label: OTHERS, value: OTHERS },
];

export const NACH_STATUS_CONSTANTS = {
    REGISTERED: 'REGISTERED',
    AUTHENTICATED: 'AUTHENTICATED',
};

export const NACH_TYPE = {
    ENACH: 'ENACH',
    PHYSICAL_NACH: 'PHYSICAL_NACH',
};

export const DIRECTOR_DETAILS = {
    HEADING: 'Fetch all directors',
    SUBHEADING:
        'Investors will need CIBIL soft pull consent for all directors (except independent & investor directors). Don’t worry! It won’t impact CIBIL score',
};

export const TEAM_MEMBER_EDIT = 'Team Member:edit';
export const RECUR_HOMEPAGE = 'https://recurclub.com';
export const DEAL_CHEST_WEBSITE = 'https://recur-club.webflow.io/deal-chest';

export const APP = 'APP';
export const PENNY_DROP_VERIFIED = 'success';
export const DECENTRO_BANK_ATTEMPTED_NOT_VERIFIED = 'attemptedNotVerified';
export const DECENTRO_BANK_FAILURE = 'failure';
export const DECENTRO_BANK_PENDING = 'pending';
export const DECENTRO_BANK_NOT_VERIFIED = 'not verified';
export const ATTEMPTED_STATUS = [
    PENNY_DROP_VERIFIED,
    DECENTRO_BANK_ATTEMPTED_NOT_VERIFIED,
    DECENTRO_BANK_PENDING,
];
export const genericEmails = [
    'accounts',
    'account',
    'info',
    'finance',
    'ceo',
    'admin',
    'contact',
    'support',
    'sales',
    'founders',
    'cfo',
    'director',
    'md',
    'connect',
    'team',
    'test',
    'mail',
];

export const REC123 = 'REC123';
